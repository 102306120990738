.bg-login {
    height: 100%;
    width: 100%;
    background-image: none;
    background-size: initial;
    background-position: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-image: url('./train1.png');
    background-size: contain;
}

.bg-login {
    height: 100vh;
    width: 100vw;
    background-image: url(./train1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
}

.bg-row {
    padding: 0;
    margin: 0;
}

.left-login {
    width: 30%;
    margin: 1.25rem;
}

.right-login {
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    box-sizing: border-box;
}

.test,
.container-fluid,
.row {
    width: 100%;
}

.logo-title-1 {
    color: #b50909;
}

.logo-title-2 {
    color: #f9a603;
}

.logo-title-1,
.logo-title-2 {
    margin-top: 5px;
}

.logo-style-login {
    height: 80px;
    width: 320px;
}

.login-patil-icons {
    display: flex;
    font-size: 1rem;
    background-color: none !important;
}

.login-facebook-icon {
    color: rgb(12, 101, 218);
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.login-arrow-position {
    position: relative;
    bottom: 20%;
    left: 30%;
}

.login-arrow-icon {
    color: #0072b1;
    animation: blink 1s infinite;
    font-size: 1.5rem;
}

.login-phone-icon {
    color: green;
    margin-right: 20px;
}

.custom-linkedin-icon {
    display: inline-block;
    position: relative;
}

.custom-linkedin-icon .fa-square {
    font-size: 1.5rem;
    color: #0476D0;
}

.custom-linkedin-icon i {
    position: absolute;
    font-size: 1.5rem;
    color: #0476D0;
}

.login-linkedin-icon {
    color: #0077B5;
}

.g {
    background: linear-gradient(to bottom, #DB4437, #DB4437, #F4B400, #0F9D58, #4285F4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

a {
    text-decoration: none;
}

.icon-space {
    margin-right: 20px;
    background-color: none !important;
}

.login-patil-container {
    background-color: #E8E8E8;
    width: fit-content;
    padding: 40px;
    padding-top: 0px;
    border-radius: 15px;
    height: fit-content;
    opacity: 1 !important;
}

.login-card-header {
    color: white;
    font-weight: bold;
    background-color: #9c170b;
    padding: 5px;
    position: relative;
    bottom: 20px;
    border-radius: 8px;
}

.card-body {
    background-color: inherit;
    border: none;
}

.login-left-align-label {
    color: black;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.login-input-field {
    border-radius: 25px;
    padding-left: 30px;
}

.patil-address-company-name {
    font-weight: bold;
    margin: 0px;
}

.patil-address-details {
    font-weight: 500;
    margin: 0px;
    font-size: 1rem;
}

.handle-logo {
    cursor: pointer;
}

.patil-group-quotation {
    font-weight: bold;
    font-style: oblique;
    font-size: 1rem;
    color: #7e41e1;
}

.patil-footer {
    height: 5%;
    background-color: rgb(45, 1, 1);
    padding: 10px;
}

.patil-footer-description {
    color: black;
    font-weight: 500;
    margin-right: 20px;
    font-size: 1rem;
}

@media (max-width: 768px) {

    .login-left-align-label {
        width: auto;
        font-size: 0.8rem;
        text-align: left;
        margin-left: 0%;
    }

    .forget-password-label {
        font-size: 0.8rem;
    }

    .login-input-field {
        font-size: small;
    }

    .patil-footer-description {
        font-size: 0.65rem;
    }

    .login-patil-icons {
        font-size: 0.6rem;
    }

    .login-arrow-icon {
        font-size: 0.75rem;
    }

    .login-card-header {
        bottom: 18px;
    }

    .login-card-heading {
        font-size: 1rem;
    }
}



/* Captcha */

.captcha-container {
    position: relative;
    top: 30px;
}

.wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 1em 2em;
    gap: 5%;
}

canvas {
    border-radius: 20px;
    background-color: white;
    padding: 0;
}

input {
    background-color: white;
}

button#reload-button {
    font-size: 1rem;
    width: 4.6em;
    height: 2em;
    background-color: rgb(209, 106, 16);
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 0.4em;
    color: #ffffff;
    position: relative;
    top: 10px;
}

.wrapper input[type='text'] {
    font-size: 1rem;
    width: 100%;
    padding: 6px;
    border: none;
    font-weight: 400;
    outline: none;
    padding-left: 10px;
    border-radius: 20px;
}

@media (max-width: 768px) {

    .wrapper input[type='text'] {
        font-size: 0.8rem;
        padding: 4px;
        padding-left: 8px;
    }
}

button#submit-button {
    width: 80px;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    margin-top: 1em;
    padding: 0.2em 0;
    border-radius: 0.4em;
    background-color: green;
    cursor: pointer;
}

button#submit-button:hover {
    background-color: rgb(53, 175, 53);
}

.spinner-container {
    height: 50px;
    width: 180px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
}

.loading-message {
    position: fixed;
    border-radius: 5px;
    padding-left: 50px;
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.login-patil-icons {
    background-color: transparent;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.blur {
    filter: blur(5px);
    pointer-events: none;
}

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}