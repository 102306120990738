.attach-button-container {
    margin:0px !important;
    font-size: 0.75rem !important;
    border-radius: 0rem !important;
}

.attach-file-settings {
    text-align: left !important;
    margin-top: 0.3rem;
    margin-bottom: 0.6rem;
    padding-left: 20px;
}

.document-label {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}

.error-message {
    font-size: 0.75rem;
    margin-top: 0rem;
}

.document-input-field {
    border: none;
    border-bottom: 0.0625rem solid grey;
    padding: 0.375rem;
    font-size: 0.75rem;
    /*box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);*/
}

.skip-checkbox {
    font-size: 0.75rem;
    position: relative;
    top: 0.2rem;
}