.my-custom-card {
    background: linear-gradient(to bottom,rgb(175, 16, 16), #facd07); /* Adjust colors as needed */
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 0.4rem 0.8rem;
    border: none;
}

.label-name {
    color: white;
    margin-right: 10px;
    font-weight: bold; 
    font-size: 18px;
}

.input-field {
    font-size: 16px;
}

.response {
    font-size: medium;
    font-weight: 500;
}

.success-response {
    color: green;
}

.failure-response {
    color: red;
}

@media (max-width: 768px) {

    .btn-primary {
        background-color: #007bff;
        color: #fff;
        font-size: 12px !important;
        padding: 0.2rem 0.5rem !important;
        border: none;
    }

    .label-name {
        color: white;
        margin-right: 10px;
        font-weight: bold; 
        font-size: 12px;
    }

    .input-field {
        font-size: 12px;
    }
}

