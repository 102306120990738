.p, .h4 {
    margin: 0px;
}

.logo-style {
    height: 50px;
    width: 80px;
    margin: 0px;
}

.navbar {
    --bs-navbar-brand-padding-y: 0rem !important;
    background-color: #E8E8E8;
}

.patil-address-heading {
    margin: 0px;
    font-size: 1rem;
    color: #9c170b;
}

.patil-address {
    font-size: 0.75rem;
    margin: 0px;
    font-weight: 500;
}

/*@media (max-width: 728px) {
    .logo-style {
        height: 30px;
        width: 50px;
        }
    }*/
.patil-address-container {
    width: 280px;
    position: relative;
    left: 35vw;
}

@media (max-width: 1024px) {
    .patil-address-container {
        width: 280px;
        position: relative;
        left: 30vw;
    }
}

@media (max-width: 820px) {
    .patil-address-container {
        width: 280px;
        position: relative;
        left: 17vw;
    }
}

@media (max-width: 540px) {
    /* .patil-address {
        font-size: 0.5rem;
        margin: 0px;
        font-weight: 400;
    } */

    .logo-style {
        height: 30px;
        width: 50px;
        margin: 0px;
    }

    .patil-address-heading {
        font-size: 0.65rem;
        margin: 0px;
    }

    .patil-address {
        font-size: 0.5rem;
    }

    .patil-address-container {
        width: 180px;
        position: none;
        left: 0px;
    }
}

.nav-item {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
}

.nav-link {
    color: #9c170b !important;
}

/* Navbar.css */
.nav-link.active {
    font-weight: bold;
    color: rgb(215, 198, 3) !important;
}

/* Navbar brand logo styles (customize as needed) */
.navbar-brand {
    cursor: pointer;
}

.right-aligned-nav {
    margin-left: auto;
}